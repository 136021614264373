import humps from 'lodash-humps-ts';
import { omit } from 'lodash';
import { snakes } from '@utils/lodash';
import { authAxios } from '@components/Auth';
import { InsuranceTypes } from '@models/InsuranceType';
import { CarClaim } from '@models/CarClaim';
import { API_V1_BASE } from '@config';

export interface CreateCarClaimParams {
  carOrderId: number;
  insuranceType: InsuranceTypes;
  claimTypes: string[];
  accidentHappenedAt: string;
  accidentPhotos: File[];
  passengersAndThirdPartiesPoliceReports: File[];
  completedClaimForms: File[];
  supportingDocuments: File[];
  totalClaimedAmount: number;
  acceptTermsAndConditions: boolean;
}

export interface GetCarClaimParams {
  carClaimId: number;
}

export class CarClaimApi {
  static create = async (params: CreateCarClaimParams) => {
    return await authAxios
      .postForm(`${API_V1_BASE}/user/car_claims`, {
        ...omit(
          snakes(params),
          [
            'accident_photos',
            'passengers_and_third_parties_police_reports',
            'completed_claim_forms',
            'supporting_documents',
            'claim_types'
          ]
        ),
        'accident_photos[]': params.accidentPhotos,
        'passengers_and_third_parties_police_reports[]': params.passengersAndThirdPartiesPoliceReports,
        'completed_claim_forms[]': params.completedClaimForms,
        'supporting_documents[]': params.supportingDocuments,
        'claim_types[]': params.claimTypes
      })
      .then((response) => humps(response.data) as CarClaim);
  };

  static get = async (params: GetCarClaimParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/car_claims/${params.carClaimId}`)
      .then((response) => humps(response.data) as CarClaim);
  };
}
