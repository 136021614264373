import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import pluralize from 'pluralize';

import { FormGroupControl, FormGroupCheckList } from '@components/Base';
import { CLAIM_TYPES, tCarClaimType } from '@models/CarClaim';

const CHOICES = map(CLAIM_TYPES, (item) => ({ label: tCarClaimType(item), value: item }));
const GROUP_PROPS = { className: 'mb-3' };

const ClaimCardBody = () => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <Card.Title>{t('common:claim')}</Card.Title>

      <Row>
        <Col>
          <FormGroupCheckList
            name="claimTypes"
            label={pluralize(t('claimForm.claimType.label'), 2)}
            choices={CHOICES}
            groupProps={GROUP_PROPS}
            required
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroupControl
            name="passengersAndThirdPartiesPoliceReports"
            type="file"
            label={t('carInsuranceClaimForm.passengersAndThirdPartiesPoliceReports.label')}
            required
            multiple
            groupProps={GROUP_PROPS}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroupControl
            name="completedClaimForms"
            type="file"
            label={t('carInsuranceClaimForm.completedClaimForms.label')}
            required
            multiple
            groupProps={GROUP_PROPS}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroupControl
            name="supportingDocuments"
            type="file"
            multiple
            label={t('carInsuranceClaimForm.supportingDocuments.label')}
            required
            groupProps={GROUP_PROPS}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroupControl
            name="totalClaimedAmount"
            label={t('claimForm.totalClaimedAmount.label')}
            required
            groupProps={GROUP_PROPS}
          />
        </Col>
      </Row>
    </Card.Body>
  );
};

export { ClaimCardBody };
