import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { isLoggedIn } from 'axios-jwt';
import { Nav, Navbar, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import UserNavDropdown from './UserNavDropdown';
import HeaderNavLink from './HeaderNavLink';
import HeaderNavbarBrand from '../HeaderNavbarBrand';
import LocaleButtonGroup from './LocaleButtonGroup';
import { ArticleNavDropdown } from './article-nav-dropdown';
import { InsuranceNavDropdown } from './insurance-nav-dropdown';
import { SIGNIN_PATH } from '@config';

const HeaderPrimary = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loggedIn = isLoggedIn();

  const handleSignIn = useCallback(() => {
    navigate(SIGNIN_PATH);
  }, []);

  return (
    <Navbar bg="body" variant="light" expand="lg" className="shadow-sm">
      <Container>
        <HeaderNavbarBrand />
        <Navbar.Toggle aria-controls="navbar" />
        <Navbar.Collapse id="navbar">
          <Nav className={classNames('me-auto', { 'mb-2 mb-lg-0': !loggedIn })}>
            <InsuranceNavDropdown />
            <HeaderNavLink />
            <ArticleNavDropdown />
          </Nav>

          {loggedIn && (
            <div className="mb-2 mb-lg-0">
              <UserNavDropdown />
            </div>
          )}

          {!loggedIn && (
            <div className="mb-3 mb-lg-0">
              <Button className="w-100" variant="light" onClick={handleSignIn}>
                <FontAwesomeIcon className="fa-fw me-2" icon={faUser} />
                {t('common:signIn')}
              </Button>
            </div>
          )}

          <div className="mb-2 mb-lg-0 ms-lg-4">
            <LocaleButtonGroup />
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export { HeaderPrimary };
