import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { isLoggedIn } from 'axios-jwt';
import { SIGNIN_PATH } from '@config';

interface Props {
  element: JSX.Element;
  redirectPath?: string;
}

const ProtectedRoute = ({ element, redirectPath = SIGNIN_PATH }: Props) => {
  const location = useLocation();

  if (isLoggedIn()) return element;

  return <Navigate to={redirectPath} replace state={{ from: location }} />;
};

export default ProtectedRoute;
