import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormGroupControl } from '@components/Base';

const VehicleInsuredCardBody = () => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <Card.Title>{t('carInsuranceClaimForm.vehicleInsured.title')}</Card.Title>
      <Row>
        <Col>
          <FormGroupControl
            name="vehicleMake"
            label={t('car.brand')}
            required
            disabled
            groupProps={{ className: 'mb-3' }}
          />
        </Col>
        <Col>
          <FormGroupControl
            name="vehicleModel"
            label={t('car.model')}
            required
            disabled
            groupProps={{ className: 'mb-3' }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroupControl
            name="vehicleYearOfManufacture"
            label={t('car.yearOfManufacture')}
            required
            disabled
            groupProps={{ className: 'mb-3' }}
          />
        </Col>
        <Col>
          <FormGroupControl
            name="vehicleEngineNumber"
            label={t('car.engineNumber')}
            required
            disabled
            groupProps={{ className: 'mb-3' }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroupControl
            name="vehicleChassisNumber"
            label={t('car.chassisNumber')}
            required
            disabled
            groupProps={{ className: 'mb-3' }}
          />
        </Col>
        <Col>
          <FormGroupControl
            name="vehicleRegistrationNumber"
            label={t('car.registrationNumber')}
            required
            disabled
            groupProps={{ className: 'mb-3' }}
          />
        </Col>
      </Row>
    </Card.Body>
  );
};

export { VehicleInsuredCardBody };
