import React from 'react';
import {
  FormGroup,
  FormGroupProps,
  FormLabel,
  FormLabelProps,
  FormText,
  FormTextProps,
  FormControl,
  FormCheck,
  FormCheckProps,
} from 'react-bootstrap';
import { useField } from 'formik';
import { map } from 'lodash';
import { FormikControlProps } from '../';
import { useLabel } from '../FormGroupBase';

type FormikCheckProps = FormCheckProps &
JSX.IntrinsicElements['input'] & {
  name: string;
};

type FormGroupCheckListProps = FormikControlProps & {
  label?: string;
  help?: string;
  labelProps?: FormLabelProps;
  groupProps?: FormGroupProps;
  helpProps?: FormTextProps;
  choices: Array<{ label: string; value: string }>;
};

const FormGroupCheckList = ({
  choices,
  label,
  help,
  labelProps,
  groupProps,
  helpProps,
  required,
  ...props
}: FormGroupCheckListProps & FormikCheckProps) => {
  const { name } = props;
  const labelText = useLabel(label, required);
  const [field, meta] = useField(name);
  const isInvalid = meta.touched && !!meta.error;
  const isValid = meta.touched && !meta.error;
  const feedback = meta.error;
  const feedbackType = 'invalid';

  return (
    <FormGroup {...groupProps} role="group" aria-labelledby="checkbox-group">
      {labelText && <FormLabel {...labelProps}>{labelText}</FormLabel>}
      {map(choices, ({ label: text, value }, idx) => (
        <FormCheck
          key={value}
          {...field}
          {...props}
          {...{ id: `${name}-${idx}`, label: text, value, feedbackType, isValid, isInvalid }}
        />
      ))}
      {feedback && (
        <FormControl.Feedback type="invalid" className="d-block">
          {feedback}
        </FormControl.Feedback>
      )}
      {help && (
        <FormText muted {...helpProps}>
          {help}
        </FormText>
      )}
    </FormGroup>
  );
};

export { FormGroupCheckList, FormGroupCheckListProps };
