import React, { useEffect, useState } from 'react';
import { toNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Modal, ListGroup, Form, FormCheck, Button, ModalProps, Alert } from 'react-bootstrap';

type Props = Pick<ModalProps, 'show' | 'onHide'> & {
  defaultIdvAmount: number;
  onConfirm: (idvAmount: number) => void;
};

const InsuredDeclaredValueModal = ({ defaultIdvAmount, show, onHide, onConfirm }: Props) => {
  const { t } = useTranslation();
  const [idvAmount, setIdvAmount] = useState(defaultIdvAmount || 0);

  const handleConfirm = () => {
    onConfirm(idvAmount);
  };

  useEffect(() => {
    setIdvAmount(defaultIdvAmount);
  }, [defaultIdvAmount]);

  return (
    <Modal show={show} onHide={onHide} size="lg" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{t('carInsuranceQuote.insuredDeclaredValue.modalTitle')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ListGroup variant="flush">
          <ListGroup.Item className="d-flex align-items-start px-0 border-0">
            <div className="w-100">
              <FormCheck.Label htmlFor="customAmount" className="ms-2">
                {t('carInsuranceQuote.insuredDeclaredValue.customAmount')}
              </FormCheck.Label>
              <Form.Control
                min={0}
                max={1000000}
                type="number"
                name="customCurrentIdv"
                id="customAmount"
                value={idvAmount === 0 ? '' : idvAmount}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setIdvAmount(toNumber(e.target.value));
                }}
              />
              <Form.Text className="text-muted">
                {t('carInsuranceQuote.insuredDeclaredValue.emptyIdvWarningHeader')}
              </Form.Text>{' '}
              <Form.Text>{t('carInsuranceQuote.insuredDeclaredValue.noteWithoutRecommendedAmount')}</Form.Text>
            </div>
          </ListGroup.Item>
        </ListGroup>

        <Alert variant="secondary">
          <span className="d-block mb-3">{t('carInsuranceQuote.insuredDeclaredValue.whatIdv')}</span>
          <p>{t('carInsuranceQuote.insuredDeclaredValue.description')}</p>
        </Alert>
      </Modal.Body>

      <Modal.Footer>
        <div className="mt-2 text-end">
          <Button variant="primary" disabled={idvAmount == 0} onClick={handleConfirm}>
            {t('common:update')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export { InsuredDeclaredValueModal };
