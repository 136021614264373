import React, { useEffect, useCallback, ReactElement } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Image } from 'react-bootstrap';
import { map, isUndefined, isNull } from 'lodash';
import { useTranslation } from 'react-i18next';
import { BackButton, ClaimBadge, Loading, LoadingError, NoData } from '@components/Base';
import { amountToUsd } from '@utils/currency';
import { getAttachmentType, isAttachmentImage, isAttachmentPdf } from '@utils/attachment';
import { selectCancerClaim } from './selector';
import { cancerClaimFetch } from './action';

interface RowItemProps {
  label: string;
  value?: string | number | ReactElement | null;
  list?: string[];
  renderItem?: (item: string) => void;
}

const RowItem = ({ label, value, list, renderItem }: RowItemProps) => {
  return (
    <Row className="mb-2">
      <Col xs={4} sm={4} md={3}>
        {label}
      </Col>
      <Col xs="auto">:</Col>
      <Col>
        <>
          {value && value}
          {list && renderItem && map(list, renderItem)}
        </>
      </Col>
    </Row>
  );
};

interface ClaimAttachmentItemProps {
  name: string;
  url: string;
}

const ClaimAttachmentItem = ({ name, url }: ClaimAttachmentItemProps) => {
  const { t } = useTranslation();
  const attachmentType = getAttachmentType(url);

  return (
    <>
      {isAttachmentImage(attachmentType) && (
        <Image src={url} thumbnail className="mb-3" data-testid={`${name}-image`} />
      )}

      {isAttachmentPdf(attachmentType) && (
        <a href={url} className="d-block mb-3" target="_blank" rel="noreferrer" data-testid={`${name}-pdf`}>
          {t('common:clickToViewDetails')}
        </a>
      )}
    </>
  );
};

const CancerClaimDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { claimableId } = useParams();
  const { cancerClaim } = useSelector(selectCancerClaim);
  const isLoaded = !isUndefined(cancerClaim);
  const isError = isNull(cancerClaim);
  const hasCancerClaim = isLoaded && !isNull(cancerClaim);

  useEffect(() => {
    dispatch(cancerClaimFetch({ id: Number(claimableId) }));
  }, [claimableId]);

  const handleBackClick = useCallback(() => {
    navigate('/user-dashboard/claim');
  }, []);

  return (
    <>
      <BackButton onClick={handleBackClick} className="mb-4" />
      {!isLoaded && <Loading />}
      {isError && <LoadingError />}
      {isLoaded && !hasCancerClaim && <NoData />}

      {hasCancerClaim && (
        <Card>
          <Card.Header>{t('common:details')}</Card.Header>
          <Card.Body>
            <RowItem label={t('common:status')} value={<ClaimBadge status={cancerClaim.status} />} />
            <RowItem
              label={t('claimForm.completedClaimForm.label')}
              value={<ClaimAttachmentItem name="completed-claim-form" url={cancerClaim.completedClaimForm} />}
            />
            <RowItem
              label={t('claimForm.supportingDocuments.label')}
              list={cancerClaim.supportingDocuments}
              renderItem={(url) => <ClaimAttachmentItem name="supporting-documents" url={url} />}
            />
            <RowItem
              label={t('claimForm.invoice.label')}
              value={<ClaimAttachmentItem name="invoice" url={cancerClaim.invoice} />}
            />
            <RowItem
              label={t('claimForm.totalClaimedAmount.label')}
              value={amountToUsd(cancerClaim.totalClaimedAmount)}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export { CancerClaimDetails };
